/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { translate } from 'react-i18next'
import WaitingConfirmation from '../containers/WaitingConfirmation'
import { useData } from '../context'
import { facebookId } from './BankAccountDetails'
import { useAnalyticsEvents } from '../hooks/useAnalyticsEvent'

const enhanced = translate()

function changeGTMId() {
  const textArea = document.createElement('textarea')
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'
  textArea.style.visibility = 'hidden'
  document.body.appendChild(textArea)

  window.dataLayer.push({
    clickId: facebookId,
  })

  textArea.click()
}

const WaitingConfirmationPage = ({ t }) => {
  const { data } = useData()
  const { email } = data

  const { sendEvent } = useAnalyticsEvents()

  const trackConfirmationEmailPageView = useCallback(() => {
    sendEvent({
      cta_reference: 'verifique-seu-email',
      deduplicate: false,
      description:
        'Evento disparado quando o usuário acessa a tela "verifique-seu-email"',
      entity: 'page',
      label: '',
      name: 'page view pagarme',
      section_reference: 'verifique-seu-email',
    })
  }, [sendEvent])

  useEffect(() => {
    trackConfirmationEmailPageView()
    changeGTMId()
  }, [trackConfirmationEmailPageView])

  return <WaitingConfirmation businessVersion="pagarme2" email={email} t={t} />
}

WaitingConfirmationPage.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(WaitingConfirmationPage)
