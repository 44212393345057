import React, { createContext, useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import * as amplitude from '@amplitude/analytics-browser'

import { defaultPlanId } from '../environment'

/**
 * @typedef {Object} IPlanContext
 * @property {string} planId - The ID of the plan, which can be a string or null.
 */

/**
 * @type {React.Context<IPlanContext>}
 */
const PlanContext = createContext({})

export const usePlanContext = () => useContext(PlanContext)

/**
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components that will have access to the PlanContext.
 * @returns {JSX.Element} The PlanContext provider with children components.
 */
const PlanProvider = ({ children }) => {
  const location = useLocation()

  const value = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return {
      planId: searchParams.get('plan_id') || defaultPlanId,
    }
  }, [location.search])

  useEffect(() => {
    amplitude.add({
      name: 'planid-plugin',
      type: 'enrichment',
      execute: async event => {
        event.event_properties = Object.assign(
          event.event_properties ?? {},
          value,
        )

        return event
      },
    })

    return () => amplitude.remove('planid-plugin')
  }, [value])

  return <PlanContext.Provider value={value}>{children}</PlanContext.Provider>
}

PlanProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PlanProvider
