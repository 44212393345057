/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { withRouter } from 'react-router-dom'
import pardot from '../vendor/pardot'
import { getIP } from '../vendor/helpers/getIP'
import { sendErrorEvent } from '../vendor/helpers/events'
import useUTMS from '../hooks/useUTMS'
import { getUtmFromCookies, utmCookieNames } from '../utils/getUtmFromCookies'
import { usePlanContext } from './PlanContext'
import { GA4ID } from '../vendor/reactGTag'

const DataContext = React.createContext({})

const Provider = ({ children }) => {
  useUTMS()
  const { planId } = usePlanContext()

  const [data, setData] = useState({})
  const [ip, setIp] = useState()

  const [cookies] = useCookies(utmCookieNames)
  const utmParams = useMemo(() => getUtmFromCookies(cookies), [cookies])

  useEffect(() => {
    const getUserIP = async () => {
      const userIp = await getIP()
      setIp(userIp)
    }

    if (!ip) getUserIP()
  }, [ip])

  const gaParams = {
    ga_session_id:
      cookies?.[`_ga_${GA4ID?.replace('G-', '')}`]
        ?.substring(6)
        .split('.')[0] || 'not_accept_cookies_yet',
    ga_user_id: cookies?._ga?.substring(6) || 'not_accept_cookies_yet',
  }

  useEffect(() => {
    ;(async () => {
      try {
        delete data?.step

        if (Object.keys(data).length) {
          const { adminName, ...filteredData } = data
          await pardot.sendFormData({
            plan_id: planId,
            ...gaParams,
            ...filteredData,
          })
        }
      } catch (error) {
        sendErrorEvent(
          `autocred - ${data?.step || ''} - send pardot error`,
          error,
        )

        /* eslint-disable no-console */
        console.error(error)
      }
    })()
  }, [data, gaParams, planId])

  const facebookParams = {
    fbc: cookies?._fbc || '',
    fbp: cookies?._fbp || '',
  }

  const valuesObj = useMemo(
    () => ({
      data,
      facebookParams,
      gaParams,
      ip,
      setData,
      utmParams,
    }),
    [data, facebookParams, gaParams, ip, utmParams],
  )

  return (
    <DataContext.Provider value={valuesObj}>{children}</DataContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

export const useData = () => useContext(DataContext)

export const DataProvider = withRouter(Provider)
