import { defaultTo } from 'ramda'

const {
  REACT_APP_API_ENVIRONMENT,
  REACT_APP_API_URL,
  REACT_APP_DEFAULT_PLAN_ID,
  REACT_APP_MIAW_CHAT_ID,
  REACT_APP_MIAW_CHAT_SCRT,
  REACT_APP_MIAW_CHAT_SRC,
  REACT_APP_MIAW_CHAT_TEAM,
  REACT_APP_RECAPTCHA_KEY,
} = process.env

const isProd = REACT_APP_API_ENVIRONMENT === 'production'

const maintainersApiUrl = isProd
  ? 'https://api.mundipagg.com/utilities/v2/maintainers'
  : 'https://stgapi.mundipagg.com/utilities/v2/maintainers'

const ocupationsApiUrl = isProd
  ? 'https://api.mundipagg.com/bigbang/v1/occupation'
  : 'https://stgapi.mundipagg.com/bigbang/v1/occupation'

const getApiUrl = defaultTo('https://stgapi.mundipagg.com/bigbang/v1')

const env = REACT_APP_API_ENVIRONMENT === 'live' ? 'live' : 'test'

const defaultPlanId = defaultTo('')(REACT_APP_DEFAULT_PLAN_ID)
const apiUrl = getApiUrl(REACT_APP_API_URL)
const recaptchaKey = REACT_APP_RECAPTCHA_KEY
const googleMarketingPlatform = {
  analyticsId: 'UA-44419105-1',
  offersPageExperimentId: 'myOl2GJpRhCMyB_ER6dekw',
  optimizeId: 'OPT-PH4QZJN',
}
const pagarme1SellerId = '5f0e0a8011d81f55b4933697'
const pagarmeApiUrl = 'https://api.pagar.me/1'

const chatMiaw = {
  id: REACT_APP_MIAW_CHAT_ID || '',
  scrt: REACT_APP_MIAW_CHAT_SCRT || '',
  src: REACT_APP_MIAW_CHAT_SRC || '',
  team: REACT_APP_MIAW_CHAT_TEAM || '',
}

export {
  apiUrl,
  googleMarketingPlatform,
  pagarme1SellerId,
  defaultPlanId,
  pagarmeApiUrl,
  recaptchaKey,
  chatMiaw,
  maintainersApiUrl,
  ocupationsApiUrl,
}

export default env
