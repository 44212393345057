import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import shouldSendEvent from './helpers/shouldSendEvent'
import usePageViewsFactory from './helpers/usePageViewsFactory'

const usePageViews = googleAnalyticsId => {
  const sendView = location =>
    window.gtag('config', googleAnalyticsId, { page_path: location.pathname })

  usePageViewsFactory('gtag', sendView)
}

const event = ({ action, category }) => {
  if (shouldSendEvent('gtag', window)) {
    window.gtag('event', action, { event_category: category })
  }
}

export const GA4ID = process.env.REACT_APP_GA_ID || 'G-XXXXXXXXXX'

const Init = ({ optimizeId }) => (
  <Helmet>
    {/* dataLayer init */}
    <script>window.dataLayer = window.dataLayer || []</script>

    {/* Google site tag (gtag.js) - Google Analytics */}
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${GA4ID}`}
    />
    <script>
      {`
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GA4ID}', { 'optimize_id': '${optimizeId}', 'send_page_view': false });
      `}
    </script>
  </Helmet>
)

Init.propTypes = {
  optimizeId: PropTypes.string.isRequired,
}

export default { event, Init, usePageViews }
