/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import PropTypes from 'prop-types'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { v4 as uuidv4 } from 'uuid'

import { create } from '../api/company'
import generateFingerprint from '../api/fingerprint'
import BankAccountData from '../containers/BankAccountData'
import { useData } from '../context'
import { googleMarketingPlatform } from '../environment'
import getExperimentVariant from '../vendor/helpers/getExperimentVariant'
import { sendErrorEvent } from '../vendor/helpers/events'
import { sendAmplitudeEvent } from '../vendor/helpers/amplitude'

import { useAnalyticsEvents } from '../hooks/useAnalyticsEvent'
import useHandleConfirmationButton from '../hooks/useConfirmationButtonHandle'
import { usePlanContext } from '../context/PlanContext'

export const facebookId = uuidv4()

const BankAccountDetails = ({ history, redirect, t }) => {
  const { planId } = usePlanContext()
  const { data, gaParams, setData, utmParams } = useData()
  const [loading, setLoading] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    handlePreviousButtonClick,
    handleSubmitForm,
  } = useHandleConfirmationButton()
  const { sendEvent } = useAnalyticsEvents()

  const trackBankAccountDetailsPageView = useCallback(() => {
    sendEvent({
      cta_reference: 'dados-bancarios',
      deduplicate: false,
      description:
        'Evento disparado quando o usuário acessa a tela "dados-bancarios"',
      entity: 'page',
      label: '',
      name: 'page view pagarme',
      section_reference: 'dados-bancarios',
    })
  }, [sendEvent])

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return ''
    }

    const token = await executeRecaptcha('createCustomer')

    return token
  }, [executeRecaptcha])

  useEffect(() => trackBankAccountDetailsPageView(), [
    trackBankAccountDetailsPageView,
  ])
  const handleSubmit = async companyData => {
    setLoading(true)

    try {
      const recaptchaToken = await handleReCaptchaVerify()

      const newRegisterData = {
        ...companyData,
        ...gaParams,
        recaptchaToken,
        webPlatform:
          companyData.otherPlatform && companyData.webPlatform.includes('Outra')
            ? `${companyData.webPlatform} - ${companyData.otherPlatform}`
            : companyData.webPlatform,
      }

      if (newRegisterData.otherPlatform) delete newRegisterData.otherPlatform

      const fpPromise = await FingerprintJS.load({
        apiKey: 'Id8ycDL3QTdHSOBAFjfS',
      })

      const fingerprint = await fpPromise.get()
      const { requestId, visitorId } = fingerprint

      const { data: hash } = await create(
        newRegisterData,
        visitorId,
        requestId,
        planId,
      )

      await generateFingerprint(fingerprint, '', newRegisterData, hash, planId)

      setData({
        ...data,
        ...newRegisterData,
        Facebook_Lead_ID: facebookId,
        metadata: {
          test_variant: getExperimentVariant(
            googleMarketingPlatform.offersPageExperimentId,
          ),
        },
        // Flag required to sinalize to Pardot that the register is complete [credenciamento/485]
        pardotRegisterFinished: true,
        utm_campaign_2: utmParams.utm_campaign,
        utm_medium_2: utmParams.utm_medium,
        utm_source_2: utmParams.utm_source,
      })

      sendAmplitudeEvent('autocred - form finished', {})

      redirect('/verifique-seu-email')
    } catch (error) {
      sendErrorEvent('autocred - bank account details - error', error)

      // eslint-disable-next-line no-undef, no-alert
      alert(t('errors.generic_message'))
    } finally {
      setLoading(false)
    }
  }

  const handlePreviousClick = () => {
    handlePreviousButtonClick()
    history.goBack()
  }

  const handleSubmitClick = companyData => {
    handleSubmitForm()
    handleSubmit(companyData)
  }

  return (
    <BankAccountData
      loading={loading}
      onPreviousButton={handlePreviousClick}
      onSubmit={handleSubmitClick}
      registerData={data}
      t={t}
    />
  )
}

BankAccountDetails.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default BankAccountDetails
