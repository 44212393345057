import PropTypes from 'prop-types'
import React from 'react'
import { DropDown, Form, TextInput } from '../../components/Form'
import Spinner from '../../components/Spinner'
import * as S from './styles'

import bankAccountTypes from '../../models/bankAccountTypes.json'
import bankCodes from '../../models/bankCodes.json'
import requiredValidation from '../../validation/required'

import hasFourDigits from '../../validation/hasFourDigits'

const isRequired = t => requiredValidation(t('errors.required_error'))
const hasMinDigits = t => hasFourDigits(t('validations.hasFourDigits'))

const BankAccountData = ({
  loading,
  onPreviousButton,
  onSubmit,
  registerData,
  t,
}) => {
  if (loading) {
    return (
      <S.SpinnerWrapper id="spinner">
        <Spinner />
      </S.SpinnerWrapper>
    )
  }

  return (
    <Form
      data={registerData}
      disableSubmit={loading}
      onPreviousButton={onPreviousButton}
      onSubmit={onSubmit}
      submitTranslationPath="pages.finish_signup"
      t={t}
    >
      <S.FirstRow>
        <DropDown
          name="bankCode"
          options={bankCodes}
          placeholder={t('pages.bank_account.select_bank_placeholder')}
          title={t('pages.bank_account.select_bank')}
          validations={[isRequired(t)]}
        />
        <DropDown
          name="bankAccountType"
          options={bankAccountTypes}
          placeholder={t('pages.bank_account.select_account_type_placeholder')}
          title={t('pages.bank_account.select_account_type')}
          validations={[isRequired(t)]}
        />
      </S.FirstRow>
      <S.SecondRow>
        <TextInput
          mask="1111"
          name="agency"
          placeholder={t('pages.bank_account.agency_placeholder')}
          title={t('pages.bank_account.agency')}
          type="number"
          validations={[isRequired(t), hasMinDigits(t)]}
        />
        <TextInput
          maxLength={1}
          name="agencyDv"
          placeholder="0"
          title={t('pages.bank_account.digit')}
          type="text"
        />
        <TextInput
          mask="1111111111111"
          name="bankAccount"
          placeholder={t('pages.bank_account.account_placeholder')}
          title={t('pages.bank_account.account')}
          type="text"
          validations={[isRequired(t)]}
        />
        <TextInput
          maxLength={2}
          name="bankAccountDv"
          placeholder="00"
          title={t('pages.bank_account.digit')}
          type="text"
          validations={[isRequired(t)]}
        />
      </S.SecondRow>
      <S.Text>
        {t('pages.bank_account.disclaimer_1')}{' '}
        <b>{t('pages.bank_account.disclaimer_2')}</b>
      </S.Text>
    </Form>
  )
}

BankAccountData.propTypes = {
  loading: PropTypes.bool,
  onPreviousButton: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  registerData: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
}

BankAccountData.defaultProps = {
  loading: false,
  registerData: {},
}

export default BankAccountData
